// -----------------------------------------------------------------------------
// Lists
// -----------------------------------------------------------------------------

ol,
ul {
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

dl {
  margin: 0;
  padding: 0;

  dt {
    margin-top: 10px;
    font-weight: 600;
  }

  dd {
    margin: 0;
  }
}
