// -----------------------------------------------------------------------------
// Font Icons
// -----------------------------------------------------------------------------

// Links
a[href^="mailto:"]::before,
a[href^="tel:"]::before,

// Menu
.menu-toggle::before,
.sub-menu-toggle::before,

// Social Media
.social-gplus a::before,
.social-twitter a::before,
.social-facebook a::before,
.social-rss a::before {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Theme Icon Padding
---------------------- */
a[href^='mailto:']::before,
a[href^='tel:']::before {
  padding-right: 4px;
  padding-bottom: 2px;
  font-size: 14px;
}

/* Link Icons
---------------------- */
a[href^='mailto:']::before {
  content: '\f0e0'; // fa-envelope
}

a[href^='tel:']::before {
  content: '\f10b'; // fa-mobile
}

/* Menu Icon
---------------------- */
.menu-toggle::before {
  content: '\f0c9'; // fa-bars
}

.menu-toggle.activated::before {
  content: '\f00d'; // fa-times 
}

.sub-menu-toggle::before {
  content: '\f078'; // fa-chevron-down
}

.sub-menu-toggle.activated::before {
  content: '\f077'; // fa-chevron-up
}


/* Social Media Icons
---------------------- */
.social-gplus a::before {
  content: '\f0d5'; // fa-google-plus
}

.social-twitter a::before {
  content: '\f099'; // fa-twitter
}

.social-facebook a::before {
  content: '\f09a'; // fa-facebook
}

.social-rss a::before {
  content: '\f09e'; // fa-rss
}
