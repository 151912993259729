// -----------------------------------------------------------------------------
// Buttons
// -----------------------------------------------------------------------------

%button-base {
  display: inline-block;
  width: auto;  
  border: 0;
  border-radius: $base-border-radius;
  padding: 0.75rem 1.25rem;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  font-size: $base-button-font-size;
  font-weight: 400;
  cursor: pointer;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"],
.button {
  @extend %button-base;
  background-color: $base-button-background-color;
  color: $base-button-font-color;
}

button:hover,
button:focus,
input[type="button"]:hover,
input[type="button"]:focus,
input[type="reset"]:hover,
input[type="reset"]:focus,
input[type="submit"]:hover,
input[type="submit"]:focus,
.button:hover,
.button:focus {
  background-color: $base-button-background-color-hover;
  color: $base-button-font-color-hover;
}

.entry-content .button:hover,
.entry-content .button:focus {
  color: $base-button-font-color-hover;
}

.block-button,
.block.button {
  display: block;
}

.btn-sm {
  padding: 0.50rem 1rem !important;
  font-size: 72% !important;
}

.btn-lg {
  padding: 1rem 1.50rem !important;
  font-size: 115% !important;
}
