// -----------------------------------------------------------------------------
// Misc
// -----------------------------------------------------------------------------

// Border Radius
//------------------------------------------------------------------------------
$base-border-radius:    3px;
$form-border-radius:    $base-border-radius;

// Shadows
//------------------------------------------------------------------------------
$form-box-shadow:       inset 0 1px 3px rgba(0, 0, 0, 0.06);
$form-box-shadow-focus: $form-box-shadow, 0 0 5px rgba(darken($form-border-color-focus, 5), 0.7);

// Transitions
//------------------------------------------------------------------------------
$image-opacity-transition:    opacity 0.2s ease-in-out;
$responsive-menu-transition:  0.4s ease-in-out;
$link-button-transition:      all 0.1s ease-in-out;
$link-color-transition:       color 0.1s linear;

// Z-Index
// --------------------------------------------------
$screen-reader-z-index: 1;
$sub-menu-z-index:      1;
$menu-mobile-z-index:   10;
