// -----------------------------------------------------------------------------
// Layout
// -----------------------------------------------------------------------------

$max-width: 1140px;

// Global Screen Sizes
// ----------------------------------------------
$smallest-screen: 320px;
$smaller-screen:  480px;
$small-screen:    810px;
$medium-screen:   1023px;
$large-screen:    1200px;

// Global Max Width Settings
// ----------------------------------------------
$base-wrap-max-width:       768px;
$wrap-max-width-medium:     960px;
$wrap-max-width-large:      $max-width;

// Global Spacing
// ----------------------------------------------
$base-margin:               30px;
$base-padding:              40px;
$base-padding-small:        20px;
$base-vertical-spacing:     0.5em;

// Layout Spacing
// ----------------------------------------------
$site-inner-padding:        30px;

// Content Spacing
// ----------------------------------------------
$content-margin:            26px;
$content-margin-small:      16px;

// Entry Spacing
// ----------------------------------------------
$entry-padding:             30px;

// Comment Spacing
// ----------------------------------------------
$comment-padding:           20px 20px 0;

// Widget Spacing
// ----------------------------------------------
$widget-padding:            30px;
$widget-padding-small:      15px;

// Navigation Spacing
// ----------------------------------------------
$menu-mobile-item-padding:  20px;

// Form Spacing
// ----------------------------------------------
$form-input-padding:        10px;
