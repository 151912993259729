// -----------------------------------------------------------------------------
// Tables
// -----------------------------------------------------------------------------

table {
  width: 100%;
  margin-bottom: $base-margin;
  border-collapse: collapse;
  border-spacing: 0;
  line-height: 2;
}

th,
td {
  padding: 10px 0;
  text-align: left;
}

th {
  font-weight: 400;
}

