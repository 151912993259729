// -----------------------------------------------------------------------------
// Site Footer
// -----------------------------------------------------------------------------

.site-footer {
  padding: $base-padding 0;
  color: $footer-font-color;
  font-size: $footer-font-size;
  text-align: center;

  p {
    margin-bottom: 0;
  }

  a {
    color: $footer-link-color;

    &:hover {
      color: $footer-link-color-hover;
    }
  }

}
