// -----------------------------------------------------------------------------
// Typography
// -----------------------------------------------------------------------------

html {
  font-size: $base-font-size;
}

body {
  background-color: $base-background-color;
  line-height: $base-line-height;
  color: $base-font-color;
  font-family: $base-font-family;
  font-weight: 400;
}

a,
button,
input:focus,
input[type="button"],
input[type="reset"],
input[type="submit"],
textarea:focus,
.button {
  transition: $link-button-transition;
}

a {
  transition: $link-color-transition;
  text-decoration: none;
  color: $base-link-color;  

  &:hover {
    color: $base-link-color-hover;
  }

  &:active,
  &:focus {
    color: $base-link-color-hover;
    outline: none;
  }
}

p {
  margin: 0 0 $content-margin-small;
  padding: 0;

	@include min-screen( $small-screen ) {
		margin-bottom: $content-margin;
	}
}

b,
strong {
  font-weight: 600;
}

hr {
  margin: 20px 0;
  border: 0;
  border-bottom: 1px solid $base-border-color;
}

pre {
  position: relative;
  max-width: 100%;
  overflow: auto;
  margin-bottom: $content-margin;  
  background: $pre-background-color;
  padding: 3% 10% 3% 5%;
  white-space: pre-wrap;
  tab-size: 4;
  color: $base-heading-color;
  font-family: $monospace;
  font-size: $base-font-size;  
}

code,
kbd,
tt,
var {
  font-family: $monospace;
  font-size: $medium-font-size;
}

address {
  margin: 0 0 $content-margin;
  font-style: italic;
}

abbr,
acronym {
  border-bottom: 1px dotted $base-border-color;
  cursor: help;
}

mark,
ins {
  background: $base-insert-color;
  text-decoration: none;
}

sup,
sub {
  position: relative;
  height: 0;
  vertical-align: baseline;
  line-height: 0;
  font-size: 75%;
}

sup {
  bottom: 1ex;
}

sub {
  top: 0.5ex;
}

small {
  font-size: 75%;
}

big {
  font-size: 125%;
}

figure {
  margin: 0;
}

blockquote,
cite,
em,
i {
  font-style: italic;
}

blockquote {
  margin: $base-margin;
  color: $base-blockquote-color;
  font-family: $blockquote-font-family;
  font-style: italic;  
  
  cite {
    display: block;
    margin-top: 15px;
    font-family: $base-font-family;
    font-style: normal;

    &::before {
      padding-right: 10px;
      content: "\2014";
    }
  }
}

/* Headings
---------------------------------------------- */

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0 0 10px;
  text-rendering: optimizeLegibility; // Fix the character spacing for headings
  line-height: $heading-line-height;
  color: $base-heading-color;
  font-family: $heading-font-family;
  font-weight: 500;  
}

h1,
.h1 {
	@include h1-font-size( );
}

h2,
.h2 {
	@include h2-font-size( );
}

h3,
.h3 {
	@include h3-font-size( );
}

h4,
.h4 {
	font-size: $h4-font-size;
}

h5,
.h5 {
	font-size: $h5-font-size;
}

h6,
.h6 {
	font-size: $h6-font-size;
}
