// -----------------------------------------------------------------------------
// Colors
// -----------------------------------------------------------------------------

// Theme Colors (private vars)
// -----------------------------------------------------------------------------

// Main brand colours
$seafoam:     #33b787;
$green:       #00a66c;

// Secondary colours
$fog-dark:    #cccccc;
$fog-medium:  #e0e0e0;
$fog-light:   #f5f5f5;

$white:       #ffffff;
$off-white:   #cccccc;
$black:       #000000;
$off-black:   #1c1e1f;

$gray-dark:   #494b4c;
$gray-medium: #676869;
$gray-light:  #a4a5a5;

$info:        #85ad74;
$success:     #85ad74;
$warning:     #f78a51;
$danger:      #b13c42;

$facebook: #3b5998;
$twitter: #00b6f1;
$youtube: #b31217;
$instagrammagenta: #c32aa3;
$instagramblue: #4c5fd7;
$instagrampurple: #7232bd;
$instagramorange: #f46f30;
$instagramyellow: #ffdc7d;
$pinterest: #cb2027;
$googleplus: #df4a32;
$linkedin: #007bb6;
$vimeoblue: #45bbff;
$vimeogreen: #aad450;
$vimeoorange: #f7b42c;
$vimeopink: #f27490;
$tumblr: #2c4762;
$snapchat: #fffc00;
$whatsappgreen: #25d366;
$whatsappteal1: #075e54;
$whatsappteal2: #128c7e;
$foursquarepink: #fc4575;
$foursquareblue: #2d5be3;
$swarm: #ffb000;
$skypeblue: #00aff0;
$skypegreen: #7fba00;
$android: #a4c639;
$stumbleupon: #eb4924;
$flickrpink: #f40083;
$flickrblue: #006add;
$yahoo: #430297;
$soundcloud: #ff5500;
$spotifygreen: #1ed760;
$spotifydarkgreen: #1db954;
$dribbble: #ea4c89;
$reddit: #ff5700;
$deviantart: #4a5d4e;
$pocket: #ee4056;
$quora: #aa2200;
$quorablue: #2b6dad;
$slideshareorange: #f7941e;
$slideshareblue: #1191d0;
$fivehundredpx: #0099e5;
$listlyorange: #df6d46;
$listlyblue: #52b1b3;
$vine: #00b489;


// Global Base Colors
// ----------------------------------------------
$base-font-color:           $gray-dark;
$base-background-color:     $white;
$base-border-color:         $fog-medium;

$base-heading-color:        $gray-dark;
$base-heading-color-hover:  $green;

$base-link-color:           $green;
$base-link-color-hover:     lighten($seafoam, 10);

$base-blockquote-color:     $white;
$base-selection-color:      $white;
$base-insert-color:         lighten($seafoam, 45);

// Button Colors
// ----------------------------------------------
$base-button-font-color:              $white;
$base-button-font-color-hover:        $green;
$base-button-background-color:        $off-black;
$base-button-background-color-hover:  lighten($base-button-background-color, 10);

$alt-button-font-color:               $white;
$alt-button-font-color-hover:         $green;
$alt-button-background-color:         $off-black;
$alt-button-background-color-hover:   lighten($base-button-background-color, 10);

// Form Colors
// ----------------------------------------------
$input-background-color:    $white;
$form-border-color-hover:   darken($base-border-color, 5);
$form-border-color-focus:   darken($base-border-color, 20);
$form-placeholder-color:    darken($base-border-color, 10);
$form-font-color:           $base-font-color;
$form-border-color:         $base-border-color;

// Header Colors
// ----------------------------------------------
$header-font-color:         $white;
$header-link-color:         $white;

// Navigation Colors
//------------------------------------------------------------------------------
$nav-link-color:                  $gray-dark;
$nav-link-color-hover:            $base-link-color;
$subnav-link-color:               $gray-dark;
$subnav-link-color-hover:         $base-link-color-hover;
$subnav-background-color:         $white;
$subnav-background-color-hover:   $white;
$nav-border-color:                $base-border-color;

// Mobile Menu Colors
//------------------------------------------------------------------------------
$menu-toggle-link-color:              $off-black;
$menu-toggle-color-hover:             $gray-light;
$menu-toggle-background-color:        transparent;
$menu-toggle-background-color-hover:  transparent;
$menu-mobile-color:                   lighten( $nav-link-color, 30 );
$menu-mobile-color-hover:             $base-link-color;
$menu-mobile-subnav-color:            lighten( $nav-link-color, 15 );
$menu-mobile-background-color:        lighten( $off-black, 3 );

// Entry Colors
// ----------------------------------------------
$blockquote-border-color:          $fog-light;
$table-border-color:               $base-border-color;
$table-header-background-color:    lighten($fog-medium, 8);
$table-background-color:           lighten($fog-light, 0.8);
$code-background-color:            $fog-light;
$code-border-color:                darken($base-border-color, 10);
$code-font-color:                  $off-black;
$pre-background-color:             $fog-light;

// Footer Colors
// ----------------------------------------------
$footer-font-color:         $base-font-color;
$footer-link-color:         $gray-medium;
$footer-link-color-hover:   $base-link-color;

// Icon Colors
// ----------------------------------------------
$media-info-toggle-color:          $fog-light;
$media-player-button-color:        $white;
$media-player-unmute-button-color: $gray-dark;
