// -----------------------------------------------------------------------------
// Common Classes
// -----------------------------------------------------------------------------

/* Screen Readers
---------------------------------------------- */

.off-screen,
.screen-reader-text {
  position: absolute;
  border: 0;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;

  &:focus {
    outline: 0;
  }
}

.skip-link .screen-reader-text:focus {
  clip: auto;
  height: auto;
  margin: 20px;
  padding: 15px;
  width: auto;
  z-index: $screen-reader-z-index;
}
