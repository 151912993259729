// -----------------------------------------------------------------------------
// Content
// -----------------------------------------------------------------------------

body {
	background: url(../images/main-bg.jpg);
  background-size: cover; 
	width: 100%;	
  height: 100%;
  padding: 5% 0 0 0;
  text-align: center;
}

/* Home page
--------------------------------------------- */

.home {
  font-size: 22px;
  font-weight: 300;
  
  .message {
    margin-bottom: 30px;
    line-height: 1;
  }
  
  .contact {
    text-align: center;
    
   .add {
      margin-bottom: 20px;
      line-height: 1;
    }    
    
    .tel {
      margin-bottom: 5px;
      line-height: 1;
    }
    
  }
  
  .social {
    margin-top: 30px;
    text-align: center;
    
    a {
      display: inline-block;
      width: 50px;
      height: 50px; 
      background: #fff;
      border-radius: 25px;
      padding-top: 7px;
    }
    
    .fa-facebook {
      color: $facebook;
    }
    
  }
  
}
