// -----------------------------------------------------------------------------
// Mixins
// -----------------------------------------------------------------------------

// Utility
// -----------------------------------------------

@mixin clearfix {
	&::after {
		clear: both;
    display: block;
		content: " ";
	}
}

@mixin equal-heights() {
	display: flex;
	flex-wrap: wrap;
}

@mixin between-screen( $lower-extent, $max-extent ) {
	@include screen( $lower-extent, $max-extent - 1px ) {
		@content;
	}
}

@mixin hover {
	&:hover,
	&:focus {
		@content;
	}
}

// Typography
// -----------------------------------------------

@mixin h1-font-size {
	font-size: $h1-font-size-small;

	@include min-screen( $small-screen ) {
		font-size: $h1-font-size;
	}
}

@mixin h2-font-size {
	font-size: $h2-font-size-small;

	@include min-screen( $small-screen ) {
		font-size: $h2-font-size;
	}
}

@mixin h3-font-size {
	font-size: $h3-font-size-small;

	@include min-screen( $small-screen ) {
		font-size: $h3-font-size;
	}
}

@mixin alt-link-color {
	color: $base-alt-link-color;

	@include hover {
		color: $base-alt-link-color-hover;
	}
}

