// -----------------------------------------------------------------------------
// Forms
// -----------------------------------------------------------------------------

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select,
textarea {
  width: 100%;
  border: 1px solid $form-border-color;
  border-radius: $form-border-radius;
  background-color: $input-background-color;
  padding: 10px;
  box-shadow: $form-box-shadow;
  color: $form-font-color;
  font-size: $form-font-size;
  font-weight: 400;
}

input[type="color"]:hover,
input[type="date"]:hover,
input[type="datetime"]:hover,
input[type="datetime-local"]:hover,
input[type="email"]:hover,
input[type="month"]:hover,
input[type="number"]:hover,
input[type="password"]:hover,
input[type="search"]:hover,
input[type="tel"]:hover,
input[type="text"]:hover,
input[type="time"]:hover,
input[type="url"]:hover,
input[type="week"]:hover,
textarea:hover {
  border-color: $form-border-color-hover;
}

input[type="color"]:focus,
input[type="date"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="email"]:focus,
input[type="month"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="text"]:focus,
input[type="time"]:focus,
input[type="url"]:focus,
input[type="week"]:focus,
textarea:focus {
  border-color: $form-border-color-focus;
  box-shadow: $form-box-shadow-focus;
  outline: none;
}

input[type="checkbox"],
input[type="image"],
input[type="radio"] {
  width: auto;
}

input {
  color: $form-placeholder-color;
  opacity: 1;
  font-weight: 400;
}
