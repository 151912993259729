// -----------------------------------------------------------------------------
// Media
// -----------------------------------------------------------------------------

embed,
iframe,
img,
object,
video,
.wp-caption {
  max-width: 100%;
}

img {
  margin: 0;
  max-width: 100%;
  height: auto;
}

a:hover img,
a:focus img {
  transition: $image-opacity-transition;
  opacity: 0.9;
}

.featured-content img,
.gallery img {
  width: auto;
}
