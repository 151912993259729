// -----------------------------------------------------------------------------
// Typography
// -----------------------------------------------------------------------------

// Global Font Families
// ----------------------------------------------
$sans-serif:     ('Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif);
$serif:          (Georgia, Times, 'Times New Roman', serif);
$monospace:      (Consolas, Monaco, 'Andale Mono', 'DejaVu Sans Mono', monospace);

// Base Font Families
// ----------------------------------------------
$base-font-family:        $sans-serif;
$heading-font-family:     $sans-serif;
$blockquote-font-family:  $serif;

// Base Font Sizes
// ----------------------------------------------
$base-font-size:          16px;

$small-font-size:         12px;
$medium-font-size:        16px;
$large-font-size:         18px;

$base-button-font-size:   16px;
$base-icon-font-size:     16px;

// Base Line Height
//------------------------------------------------------------------------------
$base-line-height:        1.625;
$heading-line-height:     1.25;

// Heading Font Sizes
// ----------------------------------------------
$h1-font-size: 38px;
$h2-font-size: 32px;
$h3-font-size: 26px;
$h4-font-size: 20px;
$h5-font-size: $base-font-size;
$h6-font-size: $base-font-size;

$h1-font-size-small: 32px;
$h2-font-size-small: 26px;
$h3-font-size-small: 20px;

// Menu Font Properties
// ----------------------------------------------
$nav-font-size:         $base-font-size;
$subnav-font-size:      14px;
$pagination-font-size:  $base-font-size;
$menu-toggle-font-size: 40px;

// Form Font Properties
// ----------------------------------------------
$form-font-size:              $base-font-size;
$form-font-family:            $base-font-family;

// Header Font Properties
// ----------------------------------------------
$header-font-family:          $heading-font-family;
$header-font-size:            $small-font-size;

$site-title-font-size:        45px;
$site-description-font-size:  20px;

// Footer Font Properties
// ----------------------------------------------
$footer-font-size:            $medium-font-size;
